import * as React from 'react';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import PageTitle from '../../components/PageTitle';
import Section from '../../components/Section';
import TripleOffsetImages from '../../components/TripleOffsetImages';
import ContentColumns from '../../components/ContentColumns';
import Subtitle from '../../components/Subtitle';
import {graphql} from 'gatsby';
import {getImage} from "../../contentful/data-provider";
import {renderRichText} from "gatsby-source-contentful/rich-text";

const AboutPage = ({data}) => {
    const {contentfulBasicPageSection} = data;

    return (
        <>
            <Layout>
                <main>
                    <PageHeader>
                        <PageTitle>
                            <h1>{contentfulBasicPageSection.title}</h1>
                        </PageTitle>
                    </PageHeader>
                    <Section hasMaxWidth hasNoBottomPadding>
                        <Subtitle isAboutUsPage>
                            <h2>{contentfulBasicPageSection.subtitle}</h2>
                        </Subtitle>
                    </Section>
                    <Section isAboutImageSection>
                        { contentfulBasicPageSection.images.length === 3 &&
                            <TripleOffsetImages
                                leftSrc={getImage(contentfulBasicPageSection.images[0]).url}
                                centerSrc={getImage(contentfulBasicPageSection.images[1]).url}
                                rightSrc={getImage(contentfulBasicPageSection.images[2]).url}
                            />
                        }
                    </Section>
                    <Section hasMaxWidth isAboutTextSection>
                        <ContentColumns>
                                {renderRichText(contentfulBasicPageSection.body)}
                        </ContentColumns>
                    </Section>
                </main>
            </Layout>
        </>
    )
}

export default AboutPage;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title} | Derschlag</title>
    )
}

export const query = graphql`
  query ($locale: String!) {
    contentfulBasicPageSection(
      contentful_id: {eq: "3VyhmHRxBY8sVlOUchmaxE"}
      node_locale: {eq: $locale}
    ) {
      ... BasicPageFields
    }
  }
`;