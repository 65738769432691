import * as React from "react";
import * as styles from "./TripleOffsetImages.module.scss";
import { Parallax } from "react-scroll-parallax";


const TripleOffsetImages = ({ leftSrc, centerSrc, rightSrc }) => {
	return (
		<div className={styles.container}>
			<div className={styles.leftImageContainer}>
				<Parallax translateY={[0, -20]}>
						<img className={styles.image} src={leftSrc} alt='' />
				</Parallax>
			</div>
			<div className={styles.centerImageContainer}>
					<img className={styles.image} src={centerSrc} alt='' />
			</div>
			<div className={styles.rightImageContainer}>
				<Parallax translateY={[-10, 20]}>
						<img className={styles.image} src={rightSrc} alt='' />
				</Parallax>
			</div>
		</div>
	);
};

export default TripleOffsetImages;
